<template>
    <SimpleTable formclass="Doctor/form" endpoint="doctors" :aditionalColumns="headers">
        <template>
            <h1>Cadastro de Médicos</h1>
            <v-divider class="mb-4"></v-divider>
        </template>
    </SimpleTable>
</template>

<script>
import SimpleTable from "@/components/core/SimpleTable";
export default {
    components: {
        SimpleTable,
    },
    data() {
        return {
            headers: [
                { text: this.$t("Habilitado"), value: "enable", align: "right", sortable: false },
                { text: this.$t("CRM"), value: "crm", align: "right", sortable: true },
                { text: this.$t("Nome"), value: "name", align: "right", sortable: true },
            ],
        };
    },
};
</script>